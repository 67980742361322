/** @jsxImportSource @emotion/react */
import React from 'react'
import { css } from '@emotion/react'
import Window from '../../../../../ui/Window/Window'
import { ReactComponent as Torobi } from './torobi.svg'
import { WindowProps } from '../../../../../ui/Window/Window'

export default function ProfileWindow({ isDesktop }: WindowProps) {
  const windowContainerStyle = css`
    .mobile & {
      top: 1.6rem;
      max-width: 95vw;
      max-height: 80vh;
      max-height: 80dvh;
    }

    width: 1110px;
    height: 630px;
    max-width: 70vw;
    max-height: 70vh;
    inset: 0;
    margin: auto;
  `

  const windowBackStyle = css`
    overflow: scroll;
  `

  const container = css`
    .mobile & {
      padding-left: 10%;
    }

    //font
    color: #707070;
    font-family: 'Zen Kaku Gothic New', sans-serif;
    font-weight: 400;
    line-height: 1.8;
    font-size: 1.1rem;

    & h1 {
      font-weight: 400;
      margin-top: 3rem;
      margin-bottom: 0;
    }

    //配置
    padding: 3% 20%;
    padding-top: 1%;
    padding-right: 5%;
  `

  const profileContainer = css`
    display: flex;
  `

  const iconStyle = css`
    margin-right: 3rem;
    margin-top: 0.5rem;
  `

  const accountList = css`
    margin: 0;
    list-style: none;
    padding-left: 0;
    & > li > a {
      color: #707070;
      text-decoration: none;

      :hover {
        color: #df6969;
      }

      transition-property: all;
      transition-duration: 300ms;
    }
  `
  const skillList = css`
    margin: 0;
    padding: 0;
    width: 80%;
    list-style: none;
    overflow: hidden;

    & li {
      margin: 0 10px 10px 0;
      padding: 0;
      display: inline-block;
      height: 28px;
      line-height: 28px;
      padding: 0 1em;
      background-color: #fff;
      border: 1px solid #aaa;
      border-radius: 9999px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      color: #707070;
      font-size: 13px;
      text-decoration: none;
    }
  `

  const linkButton = css`
    cursor: pointer;
    color: #707070;
    text-decoration: none;
    :hover {
      color: #df6969;
    }

    transition-property: all;
    transition-duration: 300ms;
  `

  // const color = (colorCode: string) => {
  //   return css`
  //     border: 1px solid ${colorCode};
  //   `
  // }

  return (
    <Window {...{ isDesktop, windowContainerStyle, windowBackStyle }}>
      <div css={container}>
        <h1>profile</h1>
        <div css={profileContainer}>
          <Torobi width={100} css={iconStyle} />
          <div>
            Name: torobi <br />
            2Dゲームやユーティリティ系のアプリを開発したい人
          </div>
        </div>
        <h1>Affiliation</h1>
        Univ: 琉球大学 工学部 知能情報コース 3年次 <br />
        Part-Time Job:{' '}
        <a css={linkButton} href="https://hugkun.com" rel="noopener noreferrer" target="_blank">
          株式会社Hugkun
        </a>
        <h1>Accounts</h1>
        <ul css={accountList}>
          <li>
            <a href="https://twitter.com/torobi2222" rel="noopener noreferrer" target="_blank">
              Twitter
            </a>
          </li>
          <li>
            <a href="https://github.com/torobi" rel="noopener noreferrer" target="_blank">
              GitHub
            </a>
          </li>
          <li>
            <a href="https://atcoder.jp/users/torobi12310" rel="noopener noreferrer" target="_blank">
              AtCoder
            </a>
          </li>
        </ul>
        <h1>Skills</h1>
        <ul css={skillList}>
          <li> TypeScript </li>
          <li> Swift </li>
          <li> Python </li>
          <li> C# </li>
          <li> Git </li>
          <li> Illustrator </li>
          <li> XD </li>
          <li> Backlog </li>
          <li> Unity </li>
          <li> UniTask </li>
          <li> Zenject/Extenject </li>
          <li> Xcode </li>
          <li> React </li>
          <li> Emotion </li>
          <li> HTML </li>
          <li> CSS </li>
          <li> CleanArchitecture </li>
        </ul>
      </div>
    </Window>
  )
}
