/** @jsxImportSource @emotion/react */
import React from 'react'
import { css } from '@emotion/react'
import Window from '../../../../ui/Window/Window'
import { WindowProps } from '../../../../ui/Window/Window'
import { useNavigate } from 'react-router-dom'
import { PATHS } from '../../../../../App'

export default function UpdatesWindow({ isDesktop }: WindowProps) {
  const windowContainerStyle = css`
    .mobile & {
      top: 1.6rem;
      max-width: 95vw;
      max-height: 80vh;
      max-height: 80dvh;
    }

    width: 850px;
    height: 450px;
    max-width: 70vw;
    max-height: 70vh;
    inset: 0;
    margin: auto;
  `

  const windowBackStyle = css`
    overflow: scroll;
  `

  const container = css`
    .mobile & {
      padding-left: 10%;
    }

    //font
    color: #707070;
    font-family: 'Zen Kaku Gothic New', sans-serif;
    font-weight: 400;
    line-height: 1.8;
    font-size: 1.1rem;

    & h1 {
      font-weight: 400;
      margin-top: 3rem;
      margin-bottom: 0;
    }

    //配置
    padding: 3% 20%;
    padding-top: 1%;
    padding-right: 5%;
  `

  const updates = css`
    margin-top: 3rem;
  `

  const linkButton = css`
    cursor: pointer;
    color: #707070;
    text-decoration: none;
    :hover {
      color: #df6969;
    }

    transition-property: all;
    transition-duration: 300ms;
  `

  const dateStyle = css`
    margin-top: 1rem;
  `

  const navigate = useNavigate()

  return (
    <Window {...{ isDesktop, windowContainerStyle, windowBackStyle }}>
      <div css={container}>
        <h1>Updates</h1>

        <div css={updates}>
          <div css={dateStyle}>2024/2/21</div>
          <div>
            <span
              css={linkButton}
              onClick={() => {
                window.location.href = 'https://blog.140degrees.com/'
              }}
            >
              Re:heat
            </span>
            を公開しました。
          </div>

          <div css={dateStyle}>2023/2/26</div>
          <div>
            <span css={linkButton} onClick={() => navigate(PATHS.MITAS_PUZZLE)}>
              MitasPuzzle
            </span>
            を公開しました。
            <br />
            <span css={linkButton} onClick={() => navigate(PATHS.PROFILE)}>
              プロフィール
            </span>
            を更新しました。
          </div>

          <div css={dateStyle}>2022/10/23</div>
          <div>レスポンシブ対応しました。</div>

          <div css={dateStyle}>2022/10/20</div>
          <div>
            <span css={linkButton} onClick={() => navigate(PATHS.MEMORY)}>
              memory
            </span>
            を公開しました。
            <br />
            <span css={linkButton} onClick={() => navigate(PATHS.ONE_FOUR_ZERO_DEGREES)}>
              140degrees
            </span>
            を公開しました。
          </div>
        </div>
      </div>
    </Window>
  )
}
